import React, { useState } from 'react';
import '../Network-Transaction/Net-transaction.css'
import { useNavigate } from 'react-router-dom';

import NetwokLogo from '../../img/2 555554565 copy.png'
import TxnRecords from '../../img/Money bag (1).png'
import TransferRecords from '../../img/Transaction.png'
import WalletRecords from '../../img/Money bag (1).png'

import '../../../../Assets/style.css';
import profilepng from '../../../../Assets/Login-img/Component 1.png';
import { useEffect } from 'react';
import { SessionTimer } from '../../../../util/UtilMethod';





const Nettransaction = () => {

  const navigate = useNavigate();
  const sessionTimer = SessionTimer();

  const [sidebarHidden, setSidebarHidden] = useState(false);
  const [allDropdown, setAllDropdown] = useState([]);

  // State to manage session time
  const [sessionTime, setSessionTime] = useState(0);

  const nav = useNavigate ();

  useEffect(() => {
    setAllDropdown(document.querySelectorAll('#sidebar .side-dropdown'));
  }, []);



  useEffect(() => {
    // Attach event listeners for dropdown elements
    allDropdown.forEach((dropdown) => {
      const parentLink = dropdown.closest('li');
      parentLink.addEventListener('click', (e) => handleMenuClick(e, dropdown));
    });

    // Set an interval to update the session time every second
    const timerInterval = setInterval(() => {
      setSessionTime((prevTime) => prevTime + 1);
    }, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(timerInterval);

      // Remove event listeners
      allDropdown.forEach((dropdown) => {
        const parentLink = dropdown.closest('li');
        parentLink.removeEventListener('click', (e) => handleMenuClick(e, dropdown));
      });
    };
  }, [allDropdown]);

  const handleDropdownClick = (e, dropdown) => {
    e.preventDefault();

    const isDropdownActive = e.currentTarget.classList.contains('active');

    // Close all dropdowns
    allDropdown.forEach((item) => {
      const aLink = item.parentElement.querySelector('a:first-child');
      aLink.classList.remove('active');
      item.classList.remove('show');
    });

    // Toggle the clicked dropdown
    e.currentTarget.classList.toggle('active', !isDropdownActive);
    dropdown.classList.toggle('show', !isDropdownActive);
  };

  const handleToggleSidebar = () => {
    setSidebarHidden((prevSidebarHidden) => !prevSidebarHidden);
  };

  const handleProfileClick = () => {
    const profileLink = document.querySelector('.profile-link');
    profileLink.classList.toggle('show');
  };

  const handleMenuClick = (e, dropdown) => {
    e.preventDefault();
    dropdown.classList.toggle('show');
  };

  // Convert seconds to HH:MM:SS format
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const pad = (num) => (num < 10 ? '0' + num : num);

    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  };




    return (
        <>

<section id="sidebar" className={sidebarHidden ? 'hide' : ''}>
    <a href="#" className="brand">
      <img src={profilepng} alt="Profile Image" className="profile-image" />
      Support
    </a>

    <ul className="side-menu">
      <li>
        <a className="active" onClick={()=>{navigate('/dashboard')}} >
          <i className="fas fa-tachometer-alt icon"></i> Dashboard
        </a>
      </li>

      <li className="divider" data-text="main">
        Main
      </li>

       {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Manage Request----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        <a href="#" onClick={(e) => handleDropdownClick(e, allDropdown[0])}>
          <i className="fas fa-folder icon fa-fw"></i> Manage Requests
          <i className="fas fa-chevron-right icon-right"></i>
        </a>

        <ul className="side-dropdown">
          <li>
            <a onClick={()=>{navigate('/kycverification')}}>
              <i className="fas fa-id-card"></i> KYC Verification
            </a>
          </li>

          <li>
            <a onClick={()=>{navigate('/traderverification')}}>
              <i className="fas fa-briefcase"></i> Trader Verification
            </a>
          </li>

          <li>
            <a href="#" onClick={()=>{navigate('/recharge')}}>
              <i className="fas fa-phone"></i> Recharge Request
            </a> 
          </li>

          <li>
            <a href="#" onClick={()=>{navigate('/c2c')}}>
              <i className="fas fa-exchange-alt"></i> C2C Request
            </a>
          </li>

          <li>
            <a href="#" onClick={()=>{navigate('/disputesattlement')}}> 
              <i className="fas fa-ban"></i> Dispute Settlement
            </a>
          </li>
        </ul>
      </li>

      {/* --@@@@@@@@@@@@@@@@@@@@@@@@---View Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        <a href="#" onClick={(e) => handleDropdownClick(e, allDropdown[1])}>
          <i className="fas fa-file-alt icon fa-fw"></i> View Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>

        <ul className="side-dropdown">
          <li>
            <a onClick={()=>{navigate('/kycverifyedreport')}}>
              <i className="fas fa-id-card"></i> KYC Report
            </a>
          </li>

          <li>
            <a onClick={()=>{navigate('/traderreport')}}>
              <i className="fas fa-briefcase"></i> Trader Report
            </a>
          </li>

          <li>
            <a href="#" onClick={()=>{navigate('/rechargereport')}}>
              <i className="fas fa-donate"></i> Recharge Report
            </a>
          </li>

          <li>
            <a href="#" onClick={()=>{navigate('/c2creport')}}>
              <i className="fas fa-money-check-alt"></i> C2C Report
            </a>
          </li>

          <li>
            <a href="#" onClick={()=>{navigate('/disputereport')}}>
              <i className="fas fa-handshake"></i> Dispute Report
            </a>
          </li>

          <li>
            <a href="#" onClick={()=>{navigate('/utrreport')}}>
              <i className="fas fa-money-check"></i> UTR Status Report
            </a>
          </li>

          <li>
            <a href="#"onClick={()=>{navigate('/purchaseReport')}}>
              <i className="fas fa-gift"></i> Purchase Report
            </a>
          </li>
        </ul>
      </li>


    {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Send Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        <a href="#" >
          <i className="fas fa-paper-plane icon fa-fw"></i> Send Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>
        <ul className="side-dropdown">
          <li>
            <a onClick={()=>{navigate('/sendecharge')}}>
              <i className="fas fa-file-alt"></i> Coin2Cash
            </a>
          </li>
        </ul>
      </li>

      <li>
        <a href="#" onClick={()=>{nav('/memberManage')}} >
          <i className="fas fa-users icon fa-fw"></i> Manage Members
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>

      <li>
        <a href="#" onClick={()=>{navigate('/enquirymanage')}}>
          <i className="fas fa-briefcase icon fa-fw"></i> Manage Enquiry
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>

      <li>
        <a href="#" onClick={()=>{navigate('/update')}}>
          <i className="fas fa-tools icon fa-fw"></i> Update Manager
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>

      <li>
        <a onClick={()=>{navigate('/ChatSupport')}}>
          <i className="fas fa-comment-alt icon fa-fw"></i>Chat Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>

      <li>
        <a onClick={()=>{navigate('/TicketSupport')}}>
          <i className="fas fa-chalkboard-teacher icon fa-fw"></i>Ticket Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>

      <li className="divider" data-text="Exit">
        Exit
      </li>

      <div className="ads">
        <button className="Btn" onClick={()=>{navigate('/loginform')}}>
          <div className="sign">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text">Logout</div>
        </button>
      </div>
    </ul> 
  </section>


{/* MAIN CONTENT */}
  <section id="content">
    <nav>
      <i className="bx bx-menu toggle-sidebar" onClick={handleToggleSidebar}></i>
      <form action="#">
        <div className="form-group">
          <span>Session Time: {formatTime(sessionTimer)}</span>
        </div>
      </form>
       
      <div className='notify'>
       <i className="fas fa-bell"></i>
     </div>


      <span className="divider"></span>
{/* --@@@@@@@@@@@@@@@@@@@@@@@@---Profile----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 
      <div className="profile">
        <img
          src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-File.png"
          onClick={handleProfileClick}
          alt="Profile"
        />
        <ul className="profile-link">
          <li>
            <a href="" onClick={()=>{navigate('/userprofile')}}>
              <i className="bx bxs-user-circle icon"></i> Profile
            </a>
          </li>
 {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Logout----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 

          <li>
            <a href="#" onClick={()=>{navigate('/loginform')}}>
              <i className="bx bxs-log-out-circle"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>

        <main>
          {/* {props.passedComponent} */}
          <div className="Network-Transation-main-container">

{/* <!--@@@@@@@@@@@@@@__________ TOP____________@@@@@@@@@@@@@@@@@@@@@--> */}

<div className="Network-Transation-page-top-updated">
  <span><img src={NetwokLogo} alt="This is an image"/></span><h1>Network-Transation</h1>
</div>

<div className="Network-Transation-page-main-table">


{/* <!--@@@@@@@@@@@@@@@@@@@@@@@_________ BUTTONS ____________@@@@@@@@@@@@@@@@@@@@@@@@@@@-->  */}

  <div className="Network-Transation-page-title-button">

    {/* <!--_________ BACK BUTTON____________--> */}

    <section className="Network-Transation-page-back_btn-updated">
      <div><a href="#" onClick={()=>{navigate('/network')}}><span><i className="fa fa-chevron-left"></i></span>Back</a></div>
  </section>

   {/* <!--_________ TOP TAB BUTTON____________--> */}

    <div className="Network-Transation-page-TOP-Network-Transation-TAB-buttons">
        <button className="Network-Transation-TAB-button-1"><img src={TxnRecords} alt="3"/>Txn. Records</button>
        <button className="Network-Transation-TAB-button-1"><img src={TransferRecords} alt="#"/>Transfer Records</button>
        <button className="Network-Transation-TAB-button-1"><img src={WalletRecords} alt="#"/>Wallet Records</button>
    </div>   
  
  </div>

{/* <!--@@@@@@@@@@@@@@@@@@@@@@@_________ MAIN TABLE CONTENT ____________@@@@@@@@@@@@@@@@@@@@@@@@@@@-->  */}

<div className="Network-Transation-table-content">
   
{/* <!--HERE can attact diffarent table insode this--> */}

</div>



{/* <!--@@@@@@@@@@@@@@@@@@@@@@@_________Network-Transation-pagination ____________@@@@@@@@@@@@@@@@@@@@@@@@@@@-->    */}
<div className="Network-Transation-Table-buttom">
  {/* <!-- For more features use the AutoHTML plugin window in figma ... --> */}
<div className="Network-Transation-Tshowing-10-results-of-46" >
 <span>
  <span className="Network-Transation-Tshowing-10-results-of-46-span">Showing </span>
     <span className="Network-Transation-Tshowing-10-results-of-46-span2">10</span>
 <span className="Network-Transation-Tshowing-10-results-of-46-span3"> Results of 46</span>
 </span>
</div>
 
<div className="Network-Transation-pagination">
 <a href="#">�</a>
 <a className="active" href="#">1</a>
 <a href="#">2</a>
 <a href="#">3</a>
 <a href="#">4</a>
 <a href="#">5</a>
 <a href="#">�</a>
</div>
</div>
</div>

</div>
          
        </main>
      </section>



        </>
    );
}

export default Nettransaction;
