import React, { useState } from 'react';
import '../../Assets/All-Css/KYC/Kyc_Verification.css';
import './TraderVerification.css'; 
import '../../Assets/style.css';
import profilepng from '../../Assets/Login-img/Component 1.png';
import { useEffect } from 'react';

import fontimg from '../../Assets/Login-img/addhaa-font.jpg';
import backimg from '../../Assets/Login-img/Aadhaar-back.png';
import panfont from '../../Assets/Login-img/Pan-card.png';
import addressproof from '../../Assets/Login-img/votercard.png';
import spamdelete from '../../Assets/Login-img/spam-delete.gif';
import spamblock from '../../Assets/Login-img/spam-block.gif';
import acceptimg from '../../Assets/Login-img/Accept-pop.png';
import rejectsub from '../../Assets/Login-img/Reject-submit.png'; 

import API from '../../api/Api';
import LoadingSpinner from '../../Componets/LoadingSpinner/LoadingSpinner';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../../Componets/Pagination/Pagination';
import { useNavigate } from "react-router-dom"; 
import { SessionTimer } from '../../util/UtilMethod';
import { getAuthToken, getTokenDuration } from '../../util/Auth';
import TimeoutPop from '../../Componets/TimeoutPop/TimeoutPop';

import LoadingGif from '../../Assets/Dashboard-img/LoadingGif.gif';

const TraderVerification = () => {
  const navigate = useNavigate();

    // TOKEN EXPIRATION   
    const [timeoutPop, setTimeoutPop] = useState(false);       
    const token = getAuthToken();
    useEffect(() => {
      if(!token) { 
        navigate('/loginform');
        return; 
      }
      if (token === 'EXPIRED') {
        navigate('/loginform');
        return; 
      }
      const tokenDuration = getTokenDuration(); 
      setTimeout(() => {
        navigate('/loginform'); 
      }, tokenDuration);     // 1*60*60*1000   
    }, [token]);  
  
    useEffect(() => {
      const tokenDuration = getTokenDuration(); 
      const timer = setTimeout(() => {
        setTimeoutPop(true);
      }, (tokenDuration - 15*60*1000));
      return () => clearTimeout(timer);
    }, []);
    // TOKEN EXPIRATION END

  const [isViewFrontPopupOpen, setViewFrontPopupOpen] = useState(false);    
  const [isViewBackPopupOpen, setViewBackPopupOpen] = useState(false);
  const [isTaxDocPopupOpen, setTaxDocPopupOpen] = useState(false);
  const [isTaxDocBackPopupOpen, setTaxDocBackPopupOpen] = useState(false);
  const [isAddressPopupOpen, setAddressPopupOpen] = useState(false);

  const [isAcceptPopupOpen, setAcceptPopupOpen] = useState(false);
  const [isAcceptComformPopupOpen, setAcceptComformPopupOpen] = useState(false);

  const [isRejectPopupOpen, setRejectPopupOpen] = useState(false);
  const [isRejectSubmitPopupOpen, setRejectSubmitPopupOpen] = useState(false);

  const [isSpamPopupOpen, setSpamPopupOpen] = useState(false);
  const [isSpamDeletePopupOpen, setSpamDeletePopupOpen] = useState(false);
  const [isSpamBlockPopupOpen, setSpamBlockPopupOpen] = useState(false);

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxAccept, setIsCheckboxAccept] = useState(false); 

  const closeViewFrontPopup = () => {
    setViewFrontPopupOpen(false);
  };

  const closeViewBackPopup = () => {
    setViewBackPopupOpen(false);
  };

  const closeTaxDocPopup = () => {
    setTaxDocPopupOpen(false);
  };

  const closeTaxDocBackPopup = () => {
    setTaxDocBackPopupOpen(false);
  };

  const closeAddressPopup = () => {
    setAddressPopupOpen(false);
  };

  const closeAcceptPopup = () => {
    setAcceptPopupOpen(false);
  };

  const closeAcceptComformPopup = () => {
    setAcceptComformPopupOpen(false);
  };

  const closeRejectionPopup = () => {
    setRejectPopupOpen(false);
  };

  const closeRejectSubmitPopup = () => {
    setRejectSubmitPopupOpen(false);
  };

  const closeSpamPopup = () => {
    setSpamPopupOpen(false);
  };

  const closeSpamDeletePopup = () => {
    setSpamDeletePopupOpen(false);
  };

  const closeSpamBlockPopup = () => {
    setSpamBlockPopupOpen(false);
  };


  const [sidebarHidden, setSidebarHidden] = useState(false);
  const [allDropdown, setAllDropdown] = useState([]);

  // State to manage session time
  const [sessionTime, setSessionTime] = useState(0);

  const nav = useNavigate ();


  useEffect(() => {
    setAllDropdown(document.querySelectorAll('#sidebar .side-dropdown'));
  }, []);

  useEffect(() => {
    // Attach event listeners for dropdown elements
    allDropdown.forEach((dropdown) => {
      const parentLink = dropdown.closest('li');
      parentLink.addEventListener('click', (e) => handleMenuClick(e, dropdown));
    });

    // Set an interval to update the session time every second
    const timerInterval = setInterval(() => {
      setSessionTime((prevTime) => prevTime + 1);
    }, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(timerInterval);

      // Remove event listeners
      allDropdown.forEach((dropdown) => {
        const parentLink = dropdown.closest('li');
        parentLink.removeEventListener('click', (e) => handleMenuClick(e, dropdown));
      });
    };
  }, [allDropdown]);

  const handleDropdownClick = (e, dropdown) => {
    e.preventDefault();

    const isDropdownActive = e.currentTarget.classList.contains('active');

    // Close all dropdowns
    allDropdown.forEach((item) => {
      const aLink = item.parentElement.querySelector('a:first-child');
      aLink.classList.remove('active');
      item.classList.remove('show');
    });

    // Toggle the clicked dropdown
    e.currentTarget.classList.toggle('active', !isDropdownActive);
    dropdown.classList.toggle('show', !isDropdownActive);
  };

  const handleToggleSidebar = () => {
    setSidebarHidden((prevSidebarHidden) => !prevSidebarHidden);
  };

  const handleProfileClick = () => {
    const profileLink = document.querySelector('.profile-link');
    profileLink.classList.toggle('show');
  };

  const handleMenuClick = (e, dropdown) => {
    e.preventDefault();
    dropdown.classList.toggle('show');
  };

  // Convert seconds to HH:MM:SS format
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const pad = (num) => (num < 10 ? '0' + num : num);

    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  };


  // API DATA //////////////////////////////////////
  const sessionTimer = SessionTimer();
  const [loading, setLoading] = useState(false);
  const [kycList, setKycList] = useState([]);
  const [singleKyc, setSingleKyc] = useState([]);
  const [idFrontInput, setIdFrontInput] = useState(null);
  const [reasonInput, setReasonInput] = useState('-'); 

  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10); 
  const [pagingCounter, setPagingCounter] = useState(1);
  
  const [arrId, setArrId] = useState([]);
  const [resMessage, setResMessage] = useState(null); 
  const [errMessage, setErrMessage] = useState(null); 

  const [pagePermission, setPagePermission] = useState([]); 
  const pagePermissionMethod = () => { 
    API.get("/staff/account/role/allow-pages").then((res) => {
      setPagePermission(res.data.data); 
    }).catch((err) => { 
    }).finally(() => {
    });  
  }

  const listKycMethod = () => {
    setLoading(true);
    API.get("/staff/market/trader/verification-request/list", {
      params: {
        page: currentPage+1,
        limit: itemsPerPage,
      }
    }).then((res) => {
      setKycList(res.data.data.docs); 
      setTotalRecords(res.data.data.totalDocs);  
      setPagingCounter(res.data.data.pagingCounter);
    }).catch((err) => {
    }).finally(() => {
      setLoading(false);
    });  
  }

  const handlePageChange = ({ selected }) => { 
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(totalRecords / itemsPerPage);

  const acceptKycMethod = () => {
    for(var i = 0;  i < arrId.length;  i++) {
      API.post("/staff/market/trader/verification-request/approve", { id: arrId[i] }).then((res) => { 
        setIsCheckboxAccept(false);
        setIsCheckboxChecked(false);
        // listKycMethod(); 
        // toast.success(res.data.message, {position:"top-center"});
        setResMessage(res.data.message);
      }).catch((err) => {
        // toast.error(err.response.data.message, {position:"top-center"});
        setErrMessage(err.response.data.message);
      }); 
    }

    // if(resMessage) {
    //   // toast.success(resMessage, {position:"top-center"});
    //   setAcceptComformPopupOpen(true);
    // }
    // if(errMessage) {
    //   toast.error(errMessage, {position:"top-center"});
    //   return;
    // }
    setAcceptComformPopupOpen(true);
    listKycMethod(); 
  }

  const spamKycMethod = () => {
    const body = {
      id: singleKyc._id
    };
    API.post("/staff/market/trader/verification-request/block", body).then((res) => { 
      listKycMethod(); 
      toast.success(res.data.message, {position:"top-center"});
    }).catch((err) => {
      toast.error(err.response.data.message, {position:"top-center"});
    });   
  }

  const deleteSpamKycMethod = () => {
    const body = {
      id: singleKyc._id
    };
    API.post("/staff/market/trader/verification-request/delete", body).then((res) => { 
      listKycMethod(); 
      toast.success(res.data.message, {position:"top-center"});
    }).catch((err) => {
      toast.error(err.response.data.message, {position:"top-center"});
    });   
  }

  const rejectKycMethod = () => {
    const body = {
      id: singleKyc._id,
      accountStatementNote: reasonInput,
    };
    API.post("/staff/market/trader/verification-request/reject", body).then((res) => { 
      listKycMethod(); 
      toast.success(res.data.message, {position:"top-center"});
    }).catch((err) => {
      toast.error(err.response.data.message, {position:"top-center"});
    });   
  }

  const verifyIdFrontMethod = () => {
    if(idFrontInput === '' || idFrontInput.length < 4) {
      toast.warning("Please enter last 4-digit of Account Number.", {position:"top-center"});
    }else {
      const body = {
        id: singleKyc._id,
        input: idFrontInput
      };

      API.post("/staff/market/trader/verification-request/verify", body).then((res) => { 
        toast.success(res.data.message, {position:"top-center"});
        setSingleKyc([]); 
        setLoadingIfm(true); 
        setDocUrl(null); 
        setDocType(null);
        listKycMethod(); 
        closeViewFrontPopup(); 
      }).catch((err) => {
        setSingleKyc([]); 
        setLoadingIfm(true); 
        setDocUrl(null); 
        setDocType(null);
        toast.error(err.response.data.message, {position:"top-center"});
      }); 
    }
  }

  // OPEN PDF VIEW POPUP
  const [docUrl, setDocUrl] = useState(null); 
  const [docType, setDocType] = useState(null); 
  const [loadingIfm, setLoadingIfm] = useState(true);
  const handleIframeLoad = () => {
    setLoadingIfm(false);
  };

  const checkFileTypeMethod = (url) => {
    if(!url) {
      toast.warning("Invalid Document Link!", {position:"top-center"});
      return;
    }

    setDocUrl(url);
    let extension = url.split('.').pop().toLowerCase();

    if (extension === 'pdf') {
      setDocType('PDF');
    }else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
      setDocType('IMAGE');
    } else {
      toast.warning("Invalid Document Link!", {position:"top-center"});
      return;
    }

    setViewFrontPopupOpen(true); 
  }

  // RELOAD PDF IFRAME
  const [iframeKey, setIframeKey] = useState(0);
  const handlePdfReload = () => {
    setIframeKey(prevKey => prevKey + 1);
  };

  useEffect(() => {
    pagePermissionMethod(); 
  }, []); 

  useEffect(() => {
    listKycMethod();
  }, [currentPage]); 


return (
<>
<section id="sidebar" className={sidebarHidden ? 'hide' : ''}> 
    <a href="#" className="brand">
      <img src={profilepng} alt="Profile Image" className="profile-image" />
      Support
    </a> 

    <ul className="side-menu">
      {pagePermission.some(el => el === 'dashboard') && 
      <li>
        <a href='#' className="active" onClick={()=>{navigate('/dashboard')}} >
          <i className="fas fa-tachometer-alt icon"></i> Dashboard
        </a>
      </li>}

      <li className="divider" data-text="main">
        Main
      </li>

       {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Manage Request----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'ManageRequests') && 
        <a href="#" >
          <i className="fas fa-folder icon fa-fw"></i> Manage Requests
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverification')}}>
              <i className="fas fa-id-card"></i> KYC Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderverification')}}>
              <i className="fas fa-briefcase"></i> Trader Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'recharge') && 
          <li>
            <a href="#" onClick={()=>{navigate('/recharge')}}>
              <i className="fas fa-phone"></i> Recharge Request
            </a> 
          </li>}

          {pagePermission.some(el => el === 'c2c') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2c')}}>
              <i className="fas fa-exchange-alt"></i> C2C Request
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputesattlement') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputesattlement')}}> 
              <i className="fas fa-ban"></i> Dispute Settlement
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'investotp') &&
      <li>
        <a href='#' onClick={()=>{navigate('/investotp')}}>
          <i className="fas fa-fingerprint icon fa-fw"></i>Invest Via OTP
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {/* --@@@@@@@@@@@@@@@@@@@@@@@@---View Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

     
      <li>
        {pagePermission.some(el => el === 'ViewReports') && 
        <a href="#" >
          <i className="fas fa-file-alt icon fa-fw"></i> View Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverifyedreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverifyedreport')}}>
              <i className="fas fa-id-card"></i> KYC Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderreport')}}>
              <i className="fas fa-briefcase"></i> Trader Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'rechargereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/rechargereport')}}>
              <i className="fas fa-donate"></i> Recharge Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'c2creport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2creport')}}>
              <i className="fas fa-money-check-alt"></i> C2C Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputereport')}}>
              <i className="fas fa-handshake"></i> Dispute Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'utrreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/utrreport')}}>
              <i className="fas fa-money-check"></i> UTR Status Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'purchaseReport') && 
          <li>
            <a href="#"onClick={()=>{navigate('/purchaseReport')}}>
              <i className="fas fa-gift"></i> Purchase Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'partnerreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/partnerreport')}}>
              <i className="fas fa-handshake"></i> Partner Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'ViewReports') && 
          <li>
            <a href="#" onClick={()=>{navigate('/noninvestedreport')}}>
              <i className="fas fa-file-alt"></i> Non Invested Report
            </a>
          </li>}

        </ul>
      </li>


    {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Send Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'SendReports') && 
        <a href="#" >
          <i className="fas fa-paper-plane icon fa-fw"></i> Send Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'sendecharge') && 
          <li>
            <a href='#' onClick={()=>{navigate('/sendecharge')}}>
              <i className="fas fa-file-alt"></i> Coin2Cash
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'memberManage') &&
      <li>
        <a href="#" onClick={()=>{nav('/memberManage')}} >
          <i className="fas fa-users icon fa-fw"></i> Manage Members
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'blockuser') &&
      <li>
        <a href="#" onClick={()=>{nav('/blockuser')}} >
          <i className="fas fa-user-circle icon fa-fw"></i> Block User
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'enquirymanage') &&
      <li>
        <a href="#" onClick={()=>{navigate('/enquirymanage')}}>
          <i className="fas fa-briefcase icon fa-fw"></i> Manage Enquiry
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'update') &&
      <li>
        <a href="#" onClick={()=>{navigate('/update')}}>
          <i className="fas fa-tools icon fa-fw"></i> Update Manager
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}
      
      {pagePermission.some(el => el === 'ChatSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/ChatSupport')}}>
          <i className="fas fa-comment-alt icon fa-fw"></i>Chat Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'TicketSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/TicketSupport')}}>
          <i className="fas fa-chalkboard-teacher icon fa-fw"></i>Ticket Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'feedback') &&
      <li>
        <a href='#' onClick={()=>{navigate('/feedback')}}> 
          <i className="fas fa-comment-medical icon fa-fw"></i>Feedbacks
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      <li className="divider" data-text="Exit">
        Exit
      </li>

      <div className="ads">
        <button className="Btn" onClick={()=>{navigate('/loginform')}}>
          <div className="sign">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text">Logout</div>
        </button>
      </div>
    </ul> 
  </section> 


{/* MAIN CONTENT */}
  <section id="content">
    <nav>
      <i className="bx bx-menu toggle-sidebar" onClick={handleToggleSidebar}></i>
      <form action="#">
        <div className="form-group">
          <span>Session Time: {formatTime(sessionTimer)}</span> 
        </div>
      </form>
       
      <div className='notify'>
       <i className="fas fa-bell"></i>
     </div>


      <span className="divider"></span>
{/* --@@@@@@@@@@@@@@@@@@@@@@@@---Profile----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 
      <div className="profile">
        <img
          src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-File.png"
          onClick={handleProfileClick}
          alt="Profile"
        />
        <ul className="profile-link">
          <li>
            <a href="" onClick={()=>{navigate('/selfprofile')}}>
              <i className="bx bxs-user-circle icon"></i> Profile
            </a>
          </li>
 {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Logout----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 

          <li>
            <a href="#" onClick={()=>{navigate('/loginform')}}>
              <i className="bx bxs-log-out-circle"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>

        <main>
          {/* {props.passedComponent} */}
          <section className="Kyc-Ver-Management-container">

{/* @@@@@@@@@@@@@@@@@@@@_____top Heading____@@@@@@@@@@@@@@@@@@@@@ */}

    <div className="kyc-ver-mange-top-heading">
      <h1><i className='bx bx-link'></i> Trader Verification </h1>
    </div>


{/* @@@@@@@@@@@@@@@@@@@@_____top filler search, back btn, date____@@@@@@@@@@@@@@@@@@@@@ */}

 {/* Back Button */}
    <div className="Kyc-ver-mange-Fillter-bar">

    {pagePermission.some(el => el === 'traderreport') && 
   <a className="kyc-ver-button" href="#" onClick={()=>{navigate('/traderreport')}}>
    <span className="kyc-ver-button__icon-wrapper">
      <svg width="10" className="button__icon-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 15">
        <path fill="currentColor" d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"></path>
      </svg>
      
      <svg className="kyc-ver-button__icon-svg kyc-ver-button__icon-svg--copy" xmlns="http://www.w3.org/2000/svg" width="10" fill="none" viewBox="0 0 14 15">
        <path fill="currentColor" d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"></path>
      </svg>
    </span>
   Reports
  </a>}

<div>
{/* {pagePermission.some(el => el === 'traderverificationBtnAccept') &&
<button className={`kyc-ver-custom-btn kyc-ver-approve ${!isCheckboxChecked ? 'disabled' : ''}`}
  onClick={() => {
    if (isCheckboxChecked) {
      setAcceptPopupOpen(true);
    }
  }}
  disabled={!isCheckboxChecked}> Accept</button>} */}

{pagePermission.some(el => el === 'traderverificationBtnAccept') &&
<button className={`kyc-ver-custom-btn kyc-ver-approve ${!isCheckboxAccept ? 'disabled' : ''}`}
  onClick={() => {
    if (isCheckboxChecked && singleKyc.verification === "MATCH") {
      setAcceptPopupOpen(true);
    }
  }}
  disabled={!isCheckboxAccept}> Accept</button>}


{pagePermission.some(el => el === 'traderverificationBtnSpam') &&
<button className={`kyc-ver-custom-btn kyc-ver-block ${!isCheckboxChecked ? 'disabled' : ''}`}
  onClick={() => {
    if (isCheckboxChecked) {
      setSpamPopupOpen(true);
    }
  }}
  disabled={!isCheckboxChecked}> Spam</button>}

{pagePermission.some(el => el === 'traderverificationBtnReject') &&
<button
  className={`kyc-ver-custom-btn kyc-ver-edit ${!isCheckboxChecked ? 'disabled' : ''}`}
  onClick={() => {
    if (isCheckboxChecked) {
      setRejectPopupOpen(true);
    }
  }}
  disabled={!isCheckboxChecked}> Reject</button>}

</div>
      
    </div>

    <table>
      <thead>
        <tr>
          <th>
            Check
            {/* <input type="checkbox" onChange={(e) => setIsCheckboxChecked(e.target.checked)}/> */}
          </th>
          <th>SL No.</th>
          <th>Member Name</th>
          <th>Country</th>
          <th>KYC ID</th>
          <th>Account Number</th>
          <th>Doc. Password</th>
          <th>Account Statement</th>
          <th>Submit Count</th>
          <th>Status</th>
        </tr>
      </thead>

      <tbody>
      {loading && <LoadingSpinner />}
      {kycList.map((data, index) => {
        return(
        <tr key={index}>
          <td>
            <input 
              type="checkbox"
              onChange={(e) => setIsCheckboxChecked(e.target.checked)}
              onClick={() => {
                setSingleKyc(data);
                if (data.verification === "MATCH") {
                  setIsCheckboxAccept(true);
                  setArrId((prev) => [...prev, data._id]);
                }
              }} 
            />
          </td>
          <td>{index+pagingCounter}</td>
          <td>{data.user?.name}</td>
          <td>{data.user?.country}</td>
          <td>{data.kycId ? data.kycId : '--'}</td>
          <td>{data.accountNumber}</td>
          <td>{data.pdfPassword ? data.pdfPassword : ''}</td>

          <td>
            {(data.verification === 'PENDING' || data.verification === 'MISMATCH') && 
            <button 
                style={{backgroundColor: '#006DD2', color: '#fff', padding: '5px 8px', border: 'none', cursor: 'pointer', borderRadius: '5px'}} 
                className="Recharge-rep-btn-popup address-btn" 
                onClick={()=> {setSingleKyc(data); checkFileTypeMethod(data.accountStatement);}}
            >View & Verify</button>
            }
            
            {data.verification === "MATCH" && <span>Verified <i class="fa-regular fa-circle-check" style={{color: '#0de589', fontSize: '16px'}}></i></span>}
          </td>

          <td>{data.submitCount ? data.submitCount : ''}</td>
          <td
            style={{
              color: data.status === 'PENDING' ? '#e8600a' : '#06b66c'
            }}
          >{data.status}</td>
        </tr>
      )})}

      </tbody>
    </table>

    <Pagination 
      pageCount={pageCount} 
      onPageChange={handlePageChange}    
    />

    {/*----##################-------- All POPUPS HERE -----------################--*/}

{/*--@@@@@@@@@@@@@@ Aadhaar font view @@@@@@@@@--*/}
{isViewFrontPopupOpen && (
 <>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup" style={{ minWidth: '540px'}}>
    <div className="kyc-ver-popup-content">
      <h2>Confirm Account Number</h2>

      <input 
        type="tel" 
        placeholder='Enter last 4-digit of Account Number' 
        name='idDocFront' 
        onChange={(e) => setIdFrontInput(e.target.value)}
        pattern="\d*" 
        maxlength="4"
      />

      {docType === 'PDF' && <>
      {loadingIfm && 
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <img src={LoadingGif} alt="logo" style={{width:'100px', height:'20px'}}/>
          <i 
            style={{color:'#1e2b8f', fontWeight:'600', fontSize:'18px', cursor:'pointer'}}
            onClick={handlePdfReload} 
            class="fa fa-refresh"
          ></i>
        </div>
      }
      <iframe 
        key={iframeKey}
        // src={`https://docs.google.com/gview?url=${docUrl}&embedded=true`} 
        // src={`https://docs.google.com/viewer?url=${docUrl}&embedded=true`} 
        src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${docUrl}`}
        width="460" 
        height="420" 
        onLoad={handleIframeLoad} 
        style={{ display: loading ? 'none' : 'block' }}
        title="Pdf View"
      />

          <a href={docUrl} style={{position:'relative', top:'10px'}}>
            <i 
              style={{color:'#5468ff', fontWeight:'600', fontSize:'18px', cursor:'pointer'}}
              class="fa fa-download"
            ></i>&nbsp;Download
          </a>
      </>}

      {docType === 'IMAGE' &&
      <div style={{ width: '480px', height: '420px', overflow: 'scroll' }}>
        <img 
          src={docUrl}  
          alt="doc" 
        />
      </div>}

      <button className="kyc-ver-close-btn" onClick={()=> {closeViewFrontPopup(); setSingleKyc([]); setLoadingIfm(true); setDocUrl(null); setDocType(null);}}>
        <i className="fas fa-times"></i>
      </button>
    </div>

    {pagePermission.some(el => el === 'traderverificationBtnVerify') &&
    <button className="done-btn" onClick={()=> {verifyIdFrontMethod();}}>
      Verify
    </button>}

  </div>
</>
)}



{/*----##################-------- All Top BUTTONS POPUPS HERE -----------################--*/}


{/*--@@@@@@@@@@@@@@  Accept button Popup @@@@@@@@@--*/}
{isAcceptPopupOpen && (
<>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">

    <div class="Accept-kyc-ver-popup-content">
        <h2>Confirmation</h2>
        <p>Are you sure want to Accept and Verify Trader?</p>

        <button class="kyc-ver-btn-Accept Accept" onClick={()=>{setAcceptPopupOpen(false); acceptKycMethod()}} >Comform</button>

        <button class="kyc-ver-btn-Accept Cancel" onClick={() => {closeAcceptPopup(); setIsCheckboxAccept(false);}} >Cancel</button>
       
        <button className="kyc-ver-close-btn" onClick={() => {closeAcceptPopup(); setIsCheckboxAccept(false);}}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}



{/*--@@@@@@@@@@@@@@  Accept Comform @@@@@@@@@--*/}
{isAcceptComformPopupOpen && (
<>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">

    <div class="Accept-kyc-ver-popup-content">
    <img src={acceptimg} alt="View Front Image" width="100" height="100" />
        <h2>Trader Verified</h2>
       
        <button className="kyc-ver-close-btn" onClick={closeAcceptComformPopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}


{/*--@@@@@@@@@@@@@@ Reject Reason @@@@@@@@@--*/}
{isRejectPopupOpen && (
<>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">
    <div className="kyc-ver-popup-content">
      <h2>Reason For Rejection</h2>
      <textarea
        className="rejection-reason-textarea"
        placeholder="Type here..........."
        onChange={(e)=> {setReasonInput(e.target.value)}}
      ></textarea>
      <button className="kyc-ver-close-btn" onClick={closeRejectionPopup}>
        <i className="fas fa-times"></i>
      </button>
    </div>
    <button className="done-btn"onClick={()=>{setRejectSubmitPopupOpen(true); setRejectPopupOpen(false); rejectKycMethod();}} >
      Submit
    </button>
  </div>
</>
)}



{/*--@@@@@@@@@@@@@@ Reject Submit popup @@@@@@@@@--*/}

{isRejectSubmitPopupOpen && (
<>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">

    <div class="Accept-kyc-ver-popup-content">
    <img src={rejectsub} alt="View Front Image" width="100" height="100" />
        <h2 style={{color:'red'}}>Trader Rejected</h2>
       
        <button className="kyc-ver-close-btn" onClick={closeRejectSubmitPopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}


{/*--@@@@@@@@@@@@@@  Spam button @@@@@@@@@--*/}
{isSpamPopupOpen && (
<>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">

    <div class="Spam-kyc-ver-popup-content">
        <h2>Spam Detected</h2>
        <p>Are you sure want to Delete or Block Trader?</p>
        <button class="kyc-ver-btn-spam Delete" onClick={()=>{setSpamDeletePopupOpen(true); setSpamPopupOpen(false); deleteSpamKycMethod()}}>Delete</button>
        <button class="kyc-ver-btn-spam Block"  onClick={()=>{setSpamBlockPopupOpen(true); setSpamPopupOpen(false); spamKycMethod()}}>Block</button>
        <button className="kyc-ver-close-btn" onClick={closeSpamPopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}

{/*--@@@@@@@@@@@@@@  Spam delete @@@@@@@@@--*/}

{isSpamDeletePopupOpen && (
<>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">

    <div class="Spam-kyc-ver-popup-content">
    <img src={spamdelete} alt="View Front Image" width="100" height="100" />
        <h2>Spammer Trader Deleted</h2>
       
        <button className="kyc-ver-close-btn" onClick={closeSpamDeletePopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}



{/*--@@@@@@@@@@@@@@  Spam Block @@@@@@@@@--*/}

{isSpamBlockPopupOpen && (
<>
  <div className="kyc-ver-overlay"></div>
  <div className="kyc-ver-popup">

    <div class="Spam-kyc-ver-popup-content">
    <img src={spamblock} alt="View Front Image" width="100" height="100" />
        <h2>Spammer Trader Blocked</h2>
       
        <button className="kyc-ver-close-btn" onClick={closeSpamBlockPopup}>
        <i className="fas fa-times"></i>
      </button>
     </div>
  
  </div>

</>
)}

</section>
</main>
</section>

    {timeoutPop && <TimeoutPop
       setTimeoutPop={setTimeoutPop}
     />}

</> 
);
};

export default TraderVerification;